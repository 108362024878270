<template>
  <div>
    <a @click="goBack" href="#">
      <aad-icon class="back-caret" name="caret-left"></aad-icon>
      Go Back
    </a>
  </div>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>
