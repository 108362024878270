const defaultSections = [
  {
    DestinationURL: "",
    DisplayMediums: ["Web"],
    IconURL: "exploreicon.png",
    ImageURL:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.newswise.com%2Fimages%2Finstitutions%2Flogos%2Faad-symbol-rgb-HiRes.png&f=1&nofb=1",
    IsActive: true,
    MainDisplay: false,
    MenuDisplay: true,
    IsLocalImage: true,
    Name: "Explore",
    SectionType: "Section",
    LinkedPageName: "Home",

    SortOrder: -3,
  },
  {
    DestinationURL: "",
    DisplayMediums: ["Web"],
    IconURL: "loginicon.jpg",
    ImageURL:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.newswise.com%2Fimages%2Finstitutions%2Flogos%2Faad-symbol-rgb-HiRes.png&f=1&nofb=1",
    IsActive: true,
    MainDisplay: false,
    MenuDisplay: true,
    IsLocalImage: true,
    Name: "Login",
    SectionType: "Section",
    LinkedPageName: "Login",
    SortOrder: -2,
  },
  {
    DestinationURL: "",
    DisplayMediums: ["Web"],
    IconURL: "notesicon.png",
    ImageURL:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.newswise.com%2Fimages%2Finstitutions%2Flogos%2Faad-symbol-rgb-HiRes.png&f=1&nofb=1",
    IsActive: true,
    MainDisplay: false,
    MenuDisplay: true,
    IsLocalImage: true,
    Name: "Notes",
    SectionType: "Section",
    SortOrder: -1,
    LinkedPageName: "Notes",
  },
];

export default defaultSections;
