import firebase from "firebase/app";
import "firebase/analytics";
import baseConfig from "@/store/baseConfig.js";

// Create a function that emits an event to firebase analytics
// Accept an eventName and props which are merged with the base props
export default class Analytics {
  static logEvent(eventName, uniqueProps) {
    // Standard props that apply to all events
    let props = {
      application: "web",
      meeting_code: baseConfig.MeetingCode,
      date_time: new Date().toUTCString(),
    };

    // Object.assign merges the passed in props and the baseProps
    if (uniqueProps) {
      props = Object.assign(props, uniqueProps);
    }

    // Log the event with the props
    firebase.analytics().logEvent(eventName, props);
  }

  static logException(exceptionName, exceptionMessage, screenName = null) {
    Analytics.logEvent("aad_app_exception", {
      exception_name: exceptionName,
      exception_message: exceptionMessage,
      url: window.location.href,
      screen_name: screenName,
    });
  }

  static logLinkClick(linkType, url, screenOrigin) {
    Analytics.logEvent("aad_link_click", {
      link_type: linkType,
      url: url,
      screen_origin: screenOrigin,
    });
  }

  static setUserData(userId, memberType, memberCategory) {
    firebase.analytics().setUserId(userId);
    firebase.analytics().setUserProperties({
      user_type: memberType || "",
      user_category: memberCategory || "",
    });
  }
}
