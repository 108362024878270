import { formatDate } from "@/mixins/formatDate.js";
import firebase from "firebase/app";

const sessionSearchModule = {
  state: {
    sessionSearchFilters: {
      searchTerm: {
        display: "Search Term",
        displayPlural: "Search Terms",
        value: undefined,
        isFilterList: false,
        analyticsName: "search_term",
      },
      favoritesOnly: {
        display: "Favorites Only",
        displayPlural: "Favorites",
        value: false,
        isFilterList: false,
        analyticsName: "favorites",
      },
      date: {
        display: "Date",
        displayPlural: "Dates",
        value: undefined,
        isFilterList: true,
        getter: "datesOfSessions",
        analyticsName: "date",
      },
      type: {
        display: "Type",
        displayPlural: "Types",
        value: undefined,
        isFilterList: true,
        getter: "typesInSessions",
        analyticsName: "type",
      },
      /* topic: {
        display: "Topic",
        displayPlural: "Topics",
        value: undefined,
        isFilterList: true,
        getter: "categoriesInSessions",
      },*/
      category: {
        display: "Category",
        displayPlural: "Categories",
        value: undefined,
        isFilterList: true,
        getter: "categoriesInSessions",
        analyticsName: "category",
      },
      whatsOnNow: {
        display: "What's On Now?",
        displayPlural: "Current Sessions",
        value: undefined,
        isFilterList: false,
        analyticsName: "whats_on_now",
      },
    },
  },
  mutations: {
    clearAllSessionSearchFilters(state) {
      for (const property in state.sessionSearchFilters) {
        state.sessionSearchFilters[property].value = undefined;
      }
    },
    clearSessionSearchFilterByKey(state, key) {
      state.sessionSearchFilters[key].value = undefined;
    },
    setSessionSearchFilterByKey(state, payload) {
      state.sessionSearchFilters[payload.key].value = payload.value;
    },
  },
  getters: {
    // Used in loading from url on session page
    allFilterKeys(state) {
      let filterKeys = [];

      for (const property in state.sessionSearchFilters) {
        filterKeys.push(property);
      }

      return filterKeys;
    },
    getActiveFiltersAsString(state) {
      let filters = [];

      for (const key in state.sessionSearchFilters) {
        if (
          key != "searchTerm" &&
          state.sessionSearchFilters[key].value != undefined &&
          state.sessionSearchFilters[key].value != false
        )
          filters.push(
            `${state.sessionSearchFilters[key].analyticsName}-${state.sessionSearchFilters[key].value}`
          );
      }

      return filters.join(";");
    },
    getSearchTerm(state) {
      return state.sessionSearchFilters["searchTerm"].value;
    },
    // Return all of the values as an object to be used in a query string
    queryStringSearchFilters(state) {
      let filters = {};
      for (const property in state.sessionSearchFilters) {
        let val = state.sessionSearchFilters[property].value;
        if (val != undefined && val != "") {
          filters[property] = val;
        }
      }

      return filters;
    },
    anySessionSearchFilters(state) {
      // Loop through keys, if any have value set, return true, otherwise return false
      for (const property in state.sessionSearchFilters) {
        let val = state.sessionSearchFilters[property].value;
        if (val != undefined && val != "") {
          return true;
        }
      }

      return false;
    },
    filteredSessions(state, getters, rootState, rootGetters) {
      // Loop through keys, if any have value set, return true, otherwise return false
      let sessions = rootGetters.allSessions;

      // Remove sessions that are test sessions unless the person is allowed to view them.
      if (!getters.canViewTestSessions) {
        sessions = sessions.filter((session) => {
          return !session.SessionCode.startsWith("T");
        });
      }

      // Match Search Terms to name and description and speaker names
      if (state.sessionSearchFilters["searchTerm"].value) {
        let lowerSearchFilter =
          state.sessionSearchFilters["searchTerm"].value.toLowerCase();

        sessions = sessions.filter((session) => {
          return (
            (session.Name &&
              session.Name.toLowerCase().includes(lowerSearchFilter)) ||
            (session.Description &&
              session.Description.toLowerCase().includes(lowerSearchFilter)) ||
            (session.SessionCode &&
              session.SessionCode.toLowerCase().includes(lowerSearchFilter))
            //|| (session.Speakers && session.Speakers.some(speaker => speaker.DisplayName.toLowerCase().includes(lowerSearchFilter)))
          );
        });
      }

      // Filter by date string
      if (state.sessionSearchFilters["date"].value) {
        let dateToFilter = state.sessionSearchFilters["date"].value;

        sessions = sessions.filter((session) => {
          let startDate = new Date(session.StartTime.seconds * 1000);
          let startDateString = formatDate(startDate);

          return startDateString == dateToFilter;
        });
      }

      // Filter by type
      if (state.sessionSearchFilters["type"].value) {
        let typeToFilter = state.sessionSearchFilters["type"].value;

        sessions = sessions.filter((session) => session.Type == typeToFilter);
      }

      // Filter by category
      if (state.sessionSearchFilters["category"].value) {
        let categoryToFilter = state.sessionSearchFilters["category"].value;

        sessions = sessions.filter((session) =>
          session.Categories.includes(categoryToFilter)
        );
      }

      // Filter by favoritesOnly

      if (
        rootGetters.isLoggedIn &&
        state.sessionSearchFilters["favoritesOnly"].value
      ) {
        let favorites = [...rootState.attendeeFavorites];
        sessions = sessions.filter((session) =>
          favorites.some(
            (favorite) => favorite.SessionId == session.id && favorite.IsActive
          )
        );
      }

      // Filter by whatsOnNow
      if (state.sessionSearchFilters["whatsOnNow"].value) {
        const MS_PER_MINUTE = 60000;

        // How many minutes advance should results show in what's on now?
        const MINUTES_IN_ADVANCE = 10;

        sessions = sessions.filter((session) => {
          // Start time with the minutes in advance accounted for.
          let sessionStart = new Date(
            session.StartTime.seconds * 1000 -
              MS_PER_MINUTE * MINUTES_IN_ADVANCE
          );
          let sessionEnd = new Date(session.EndTime.seconds * 1000);

          // Current date, might need to be in the same timezone as the meeting? I don't know for sure?
          let currentDateTime = new Date();

          return (
            sessionStart <= currentDateTime && currentDateTime <= sessionEnd
          );
        });
      }

      // Log to firebase if any filters
      if (
        state.sessionSearchFilters["searchTerm"].value ||
        state.sessionSearchFilters["date"].value ||
        state.sessionSearchFilters["type"].value ||
        state.sessionSearchFilters["category"].value ||
        (rootGetters.isLoggedIn &&
          state.sessionSearchFilters["favoritesOnly"].value)
      ) {
        firebase.analytics().logEvent("web_search_update", {
          search_term: state.sessionSearchFilters["searchTerm"].value || null,
          date: state.sessionSearchFilters["date"].value || null,
          type: state.sessionSearchFilters["type"].value || null,
          category: state.sessionSearchFilters["category"].value || null,
          favorites_only:
            (rootGetters.isLoggedIn &&
              state.sessionSearchFilters["favoritesOnly"].value) ||
            null,
          application: "web",
        });
      }

      // Sort by date
      return sessions.sort((a, b) => {
        return (
          a.StartTime.seconds - b.StartTime.seconds ||
          a.SessionCode.localeCompare(b.SessionCode)
        );
      });
    },
    categoriesInSessions(state, getters) {
      // Loop through keys, if any have value set, return true, otherwise return false
      let results = {};

      getters.filteredSessions.forEach((session) => {
        session.Categories.forEach((category) => {
          // If it doesn't exist, instantiate that key
          if (results[category] == undefined) {
            results[category] = 0;
          }

          // Add one to the count
          results[category] += 1;
        });
      });

      return results;
    },
    typesInSessions(state, getters) {
      // Loop through keys, if any have value set, return true, otherwise return false
      let results = {};

      getters.filteredSessions.forEach((session) => {
        // If it doesn't exist, instantiate that key
        if (results[session.Type] == undefined) {
          results[session.Type] = 0;
        }

        // Add one to the count
        results[session.Type] += 1;
      });

      return results;
    },
    datesOfSessions(state, getters, rootState, rootGetters) {
      // Loop through keys, if any have value set, return true, otherwise return false
      let results = {};

      // Return null if the meeting and sessions haven't been loaded
      if (
        rootGetters.currentMeeting == undefined ||
        getters.filteredSessions == undefined
      ) {
        return {};
      }

      getters.filteredSessions.forEach((session) => {
        // StartDate
        let startDate = new Date(session.StartTime.seconds * 1000);
        let startDateString = formatDate(startDate);

        // If it doesn't exist, instantiate that key
        if (results[startDateString] == undefined) {
          results[startDateString] = 0;
        }

        // Add one to the count
        results[startDateString] += 1;
      });

      return results;
    },
  },
};

export default sessionSearchModule;
