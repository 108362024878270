<template>
  <div>
    <a
      :href="route.DestinationURL"
      v-if="route.SectionType === 'Url'"
      target="_blank"
      @click="logSidebarClick(route.DestinationURL)"
      @click.middle="logSidebarClick(route.DestinationURL)"
    >
      <p class="sidebar-navigation-item">
        <img
          v-if="route.IsLocalImage"
          class="sidebar-item-icon rounded-circle"
          :src="require('@/assets/' + route.IconURL)"
          :alt="route.Name + ' icon'"
        />
        <img
          v-else
          class="sidebar-item-icon"
          :src="route.IconURL"
          :alt="route.Name + ' icon'"
        />

        {{ route.Name }}
      </p>
    </a>

    <!-- Logout custom logic -->
    <router-link
      v-else-if="route.LinkedPageName == 'Login' && user"
      :to="{ name: 'Login', query: { redirect: '/', name: 'Logout' } }"
      @click.native="logout"
      class="sidebar-navigation-item-link"
    >
      <p class="sidebar-navigation-item">
        <img
          v-if="route.IsLocalImage"
          class="sidebar-item-icon rounded-circle"
          :src="require('@/assets/' + route.IconURL)"
          :alt="route.Name + ' icon'"
        />
        <img
          v-else
          class="sidebar-item-icon"
          :src="route.IconURL"
          :alt="route.Name + ' icon'"
        />
        Logout
      </p>
    </router-link>

    <!-- Non URL Section -->
    <router-link
      v-else
      :to="{ name: route.LinkedPageName || 'NotFound' }"
      class="sidebar-navigation-item-link"
      @click.native="logSidebarClick(route.LinkedPageName)"
      @click.native.middle="logSidebarClick(route.LinkedPageName)"
    >
      <p class="sidebar-navigation-item">
        <img
          v-if="route.IsLocalImage"
          class="sidebar-item-icon rounded-circle"
          :src="require('@/assets/' + route.IconURL)"
          :alt="route.Name + ' icon'"
        />
        <img
          v-else
          class="sidebar-item-icon"
          :src="route.IconURL"
          :alt="route.Name + ' icon'"
        />

        <span v-if="route.LinkedPageName != 'Login'">{{ route.Name }}</span>

        <!-- Custom Logic for switch Login/Logout -->
        <span v-else>{{ user ? "Logout" : "Login" }}</span>
      </p>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Analytics from "@/mixins/analytics";

export default {
  props: ["route"],
  methods: {
    logout: function () {
      this.$store.dispatch("logout");
    },
    logSidebarClick(destinationUrl) {
      Analytics.logEvent("aad_navigation_click", {
        analytics_name: this.route.AnalyticsName,
        navigation_type: "side_bar",
        tile_name: this.route.Name,
        detination_url: destinationUrl,
      });
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped>
.sidebar-navigation-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #dee3ec;
  padding: 5px 0;
  margin: 0;
}

.sidebar-item-icon {
  height: 25px;

  width: 25px;
  margin: 5px;
}
</style>
