<template>
  <svg>
    <use :xlink:href="require('@/assets/svg-sprite.svg') + '#' + name"></use>
    <!--  -->
  </svg>
</template>
<script>
export default {
  props: ["name"],
};
</script>
