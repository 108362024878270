<template>
  <div
    class="card"
    :class="{
      'card-lg': type === 'default-lg',
      'default-lg': type === 'default-lg',
    }"
  >
    <!-- Image with or without various types of links -->
    <router-link
      v-if="routeLink != undefined"
      :to="routeLink"
      @click.native="logNavigation(routeLink)"
      @click.native.middle="logNavigation(routeLink)"
    >
      <img class="card-img-top" :src="imageUrl" :alt="altText" />
    </router-link>
    <a
      :href="href"
      v-else-if="href != undefined"
      target="_blank"
      @click="logNavigation(href)"
      @click.middle="logNavigation(href)"
    >
      <img class="card-img-top" :src="imageUrl" :alt="altText" />
    </a>
    <div v-else>
      <img class="card-img-top" :src="imageUrl" :alt="altText" />
    </div>

    <!-- Card Body -->
    <div class="card-body">
      <router-link
        v-if="routeLink != undefined"
        :to="routeLink"
        class="card-title"
        :class="{ 'card-title-lg': type === 'default-lg' }"
        @click.native="logNavigation(routeLink)"
        @click.native.middle="logNavigation(routeLink)"
      >
        {{ title }}
      </router-link>
      <a
        v-else-if="href != undefined"
        :href="href"
        class="card-title"
        :class="{
          'card-title-lg': type === 'default-lg',
        }"
        target="_blank"
        @click="logNavigation(href)"
        @click.middle="logNavigation(href)"
      >
        {{ title }}
      </a>
      <p
        v-else
        class="card-title"
        :class="{
          'card-title-lg': type === 'default-lg',
        }"
      >
        {{ title }}
      </p>

      <p v-if="description != ''" class="card-text">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import Analytics from "@/mixins/analytics";

export default {
  props: {
    type: {
      type: String,
      default: "default-lg",
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    href: {
      type: String,
    },
    routeLink: {
      type: Object,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
    },
    analyticsName: {
      type: String,
    },
    logClick: {
      type: String,
    },
    logEventName: {
      type: String,
    },
  },
  methods: {
    logNavigation(destinationUrl) {
      console.log(this.analyticsName, "ANALYTICS");
      if (this.logEventName == "aad_navigation_click") {
        Analytics.logEvent(this.logEventName, {
          analytics_name: this.analyticsName,
          navigation_type: "tile",
          tile_name: this.title,
          detination_url: destinationUrl,
        });
      } else if (this.logEventName == "aad_map_click") {
        Analytics.logEvent(this.logEventName, {
          map_name: this.title,
        });
      }
    },
  },
};
</script>
