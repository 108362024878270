<template>
  <div>
    <aad-icon class="icon loader-spin" name="aad-loader"></aad-icon>

    <h4 class="text-center mt-4" v-if="!message">
      Loading... Please wait a moment!
    </h4>
    <h4 class="text-center mt-4" v-else>{{ message }}</h4>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>
