<template>
  <header class="header-members">
    <section class="row header-topbar">
      <section class="col-auto header-topbar-col">
        <a class="header-AAD-logo-container" href="/">
          <svg
            class="header-AAD-logo ml-xl-0 ml-2 my-md-2 my-1 mr-md-4 mr-1"
            aria-labelledby="header-logo-title"
            role="img"
          >
            <title id="header-logo-title">Go to AAD Home</title>

            <use
              :xlink:href="
                require('@/assets/svg-sprite.svg') + '#aad-logo-white'
              "
            />
          </svg>

          <svg
            class="header-AAD-logo-hover ml-xl-0 ml-2 my-md-2 my-1 mr-md-4 mr-1"
            aria-labelledby="header-logo-title"
            role="img"
          >
            <title id="header-logo-title">Go to AAD Home</title>

            <use
              :xlink:href="
                require('@/assets/svg-sprite.svg') + '#aad-logo-white-hover'
              "
            />
          </svg>
        </a>
      </section>

      <section class="col-auto header-topbar-col mx-2 d-none d-sm-inline-block">
        <a
          href="https://www.aad.org"
          class="btn btn-header-members btn-header-home"
        >
          <aad-icon class="header-icon" id="header-home" name="home" />
          AAD.org Home
        </a>
      </section>

      <section class="col-auto header-topbar-col right-align-col">
        <the-sidebar-toggle-button />
      </section>
    </section>
    <!--<hr class="header-members-divider" />-->
  </header>
</template>
<script>
import TheSidebarToggleButton from "@/components/TheSidebarToggleButton.vue";

export default {
  components: {
    TheSidebarToggleButton,
  },
};
</script>

<style scoped>
.header-icon {
  top: 0;
  vertical-align: sub;
}
.header-topbar {
  min-height: 80px;
  height: auto;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .header-topbar {
    min-height: 65px;
  }
  .header-AAD-logo {
    height: 50px;
    width: 115px;
  }
}
</style>
