<template>
  <div>
    <aad-loader
      v-if="sections == undefined || sections.length == 3"
      message=" "
    >
    </aad-loader>
    <nav v-else>
      <the-sidebar-navigation-item
        v-for="section in sections"
        :route="section"
        :key="section.SortOrder"
      ></the-sidebar-navigation-item>
    </nav>
  </div>
</template>

<script>
import TheSidebarNavigationItem from "@/components/TheSidebarNavigationItem.vue";

export default {
  components: {
    TheSidebarNavigationItem,
  },
  computed: {
    sections() {
      return this.$store.getters.sidebarSections;
    },
  },
  mounted() {
    this.getSections();
  },
  methods: {
    getSections() {
      this.$store.dispatch("loadSections");
    },
  },
};
</script>
