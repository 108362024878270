<template>
  <div>
    <the-header class="header" v-if="!mobileView" />
    <div
      id=""
      :class="{ 'mobile-view': mobileView }"
      class="container-fluid row content beneath-header"
    >
      <the-sidebar class="sidebar" v-if="!cmeOnly && !mobileView" />
      <div
        id="content"
        :class="{
          'blur-content': $store.state.mobileSidebarOpen,
          'col-lg-9 d-md-12': !cmeOnly && !mobileView,
          'col-12': cmeOnly || mobileView,
        }"
      >
        <transition :name="transitionName" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import TheHeader from "./components/TheHeader.vue";
import TheSidebar from "./components/TheSidebar.vue";
import baseConfig from "@/store/baseConfig.js";

require("@/assets/MeetingWebAppStyles.css");
require("@/assets/print.css");

export default {
  name: "App",
  components: { TheHeader, TheSidebar },
  data() {
    return {
      cmeOnly: baseConfig.CmeOnly,
      transitionName: "",
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "AAD Meeting",
    // all titles will be injected into this template
    titleTemplate: "%s | AAD Meeting Web App",
  },
  computed: {
    mobileView: function () {
      return (
        this.$route.query.mobileView == "True" ||
        this.$route.query.mobileView == "true"
      );
    },
  },
  watch: {
    $route(to, from) {
      const toName = to.name;
      const fromName = from.name;

      if (
        (toName == "CME" && fromName == "CMESurvey") ||
        (toName == "CMESurvey" && fromName == "CME")
      ) {
        this.transitionName = "cme-change";
      } else {
        this.transitionName = "quick-fade";
      }
    },
  },
};
</script>

<style scoped>
.cme-change-leave-active,
.cme-change-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.cme-change-enter {
  transform: translate(100%, 0);
  opacity: 0%;
}
.cme-change-leave-to {
  transform: translate(100%, 0);
  opacity: 0%;
}

.quick-fade-leave-active {
  transition: opacity 0.25s;
}

.quick-fade-enter-active {
  transition: opactiy 0.05s;
}
.quick-fade-enter, .quick-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* End Animations */
.beneath-header {
  margin-top: 80px;
}

.mobile-view {
  margin-top: 0px;
}

#content {
  padding: 10px;
  overflow-x: hidden;
}

@media (max-width: 991.98px) {
  .blur-content {
    filter: blur(4px);
    -webkit-transition: 0.5s -webkit-filter cubic-bezier(0, 0, 0.2, 1);
    -moz-transition: 0.5s -moz-filter cubic-bezier(0, 0, 0.2, 1);
    -moz-transition: 0.5s filter cubic-bezier(0, 0, 0.2, 1);
    -ms-transition: 0.5s -ms-filter cubic-bezier(0, 0, 0.2, 1);
    -o-transition: 0.5s -o-filter cubic-bezier(0, 0, 0.2, 1);
    transition: 0.5s filter cubic-bezier(0, 0, 0.2, 1),
      0.5s -webkit-filter cubic-bezier(0, 0, 0.2, 1);
  }
}

@media screen and (max-width: 768px) {
  #beneath-header {
    margin-top: 65px;
  }

  #content {
    overflow-wrap: break-word;
  }
}
</style>
