<template>
  <div class="card-deck">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "default-lg-full",
    },
  },
};
</script>
